<template>
  <div id="influencer-profile">
    <h3>Influencer Profile</h3>
    <b-card class="mb-3" v-if="influencer && influencer.nickname" :header="influencer.nickname">
        <b-card-text>
            <b-row>
            <b-col md="6">
                <p><strong>Name:</strong> {{ influencer.firstname }} {{ influencer.firstname }}</p>
                <p><strong>Email:</strong> {{ influencer.email }}</p>
                <!-- Agrega más campos si es necesario -->
            </b-col>
            <b-col md="6">
                <p><strong>Timezone:</strong> {{ influencer.timezone }}</p>
            </b-col>
            </b-row>
        </b-card-text>
    </b-card>

    <div v-if="videos.length === 0">
      <p><strong>No videos associated with this influencer.</strong></p>
    </div>

    <div v-else>
      <b-table :items="videos" :fields="fields" striped hover>
        <template v-slot:cell(folder_id)="data">
        {{ data.item.folder_id }}
      </template>

        <template v-slot:cell(title)="data">
          {{ data.item.title }}
        </template>
        
        <template v-slot:cell(description)="data">
          {{ data.item.description }}
        </template>

        <template v-slot:cell(url)="data">
          <b-link :href="data.item.url" target="_blank">
            {{ data.item.url }}
          </b-link>
        </template>

        <template v-slot:cell(actions)="data">
          <!-- <b-button @click="editVideo(data.item.id)" variant="info" class="m-1">
            <i class="fas fa-edit text-white"></i>
          </b-button> -->
          <b-button @click="deleteVideo(data.item.video_id, data.item.folder_id)" variant="danger" class="m-1">
            <i class="fas fa-trash-alt"></i>
          </b-button>
        </template>
      </b-table>
    </div>

    <template>
      <div>
        <!-- Información del perfil del influencer -->

        <!-- Botón para añadir nuevo video -->
        <button @click="openUploadModal" class="btn btn-success mt-3">
          Add video
        </button>

        <!-- Modal para subir el video -->
        <!-- <div v-if="showUploadModal">
          <div class="modal-backdrop"></div>
          <div class="modal show" style="display: block;">
            <button type="button" class="btn-close" @click="closeUploadModal">
              <i class="fas fa-times"></i>
            </button>
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Upload video for {{ influencer.nickname }}</h5>
                  <button type="button" class="btn-close" @click="closeUploadModal"></button>
                </div>
                <div class="modal-body">
                  <input type="file" @change="handleFileUpload" accept="video/*" />
                  
                  <div v-if="videoPreview" class="mt-3">
                    <h6>Preview:</h6>
                    <video :src="videoPreview" controls width="100%"></video>
                  </div>
                </div>
                <div class="modal-footer">
                  <button class="btn btn-primary" @click="uploadVideo">Upload video</button>
                  <button class="btn btn-secondary" @click="closeUploadModal">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <template>
          <!-- Modal para subir el video -->
          <div v-if="showUploadModal">
            <div class="modal-backdrop"></div>
            <div class="modal show" style="display: block;">
              <button type="button" class="btn-close" @click="closeUploadModal">
                <i class="fas fa-times"></i>
              </button>
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Upload video for {{ influencer.nickname }}</h5>
                    <button type="button" class="btn-close" @click="closeUploadModal"></button>
                  </div>
                  <div class="modal-body">
                    <b-form-group label="Video Title" label-for="title">
                      <b-form-input id="title" v-model="videoTitle" placeholder="Enter video title"></b-form-input>
                    </b-form-group>

                    <b-form-group label="Video Description" label-for="description">
                      <b-form-textarea id="description" v-model="videoDescription" placeholder="Enter video description" rows="3"></b-form-textarea>
                    </b-form-group>

                    <b-form-group label="Select Video" label-for="file">
                      <input type="file" @change="handleFileUpload" accept="video/*" id="file" />
                    </b-form-group>

                    <!-- Video Preview -->
                    <div v-if="videoPreview" class="mt-3">
                      <h6>Preview:</h6>
                      <video :src="videoPreview" controls width="100%"></video>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button class="btn btn-primary" @click="uploadVideo">Upload video</button>
                    <button class="btn btn-secondary" @click="closeUploadModal">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- Aquí iría cualquier otra información del influencer -->
      </div>
    </template>
    <!-- <div v-if="influencer">
      <h2>{{ influencer.name }}</h2>
      <p><strong>Email:</strong> {{ influencer.email }}</p>
      <p><strong>Descripción:</strong> {{ influencer.description }}</p>
    </div> -->
    <!-- <div v-else>
      <p>Cargando datos del influencer...</p>
    </div> -->
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    data() {
      return {
        influencer: {
          firstname: '',
          lastname: '',
          nickname: ''
        },
        fields: [
          { key: 'video_id', label: 'Video ID' },  // Ya estaba en la definición
          { key: 'path', label: 'Path' },  // Nuevo campo para el Wistia ID
          { key: 'title', label: 'Title' },
          { key: 'description', label: 'Description' },
          { key: 'video_url', label: 'Video URL' },
          { key: 'actions', label: 'Actions', sortable: false }
        ],
        videos: [],
        showUploadModal: false,
        file: null,
        videoPreview: null,
        videoTitle: '',        // Variable para almacenar el título del video
        videoDescription: ''   // Variable para almacenar la descripción del video
      };
    },
    mounted() {
      const userId = this.$route.params.influencerId; // Obtén el ID del influencer desde la ruta
      const influencerData = JSON.parse(localStorage.getItem(`influencer_${userId}`)); 
      console.log(influencerData, '0000'); // Intenta obtener los datos del localStorage
      
      if (influencerData) {
        // Si los datos están en localStorage, asígnalos al objeto influencer
        this.influencer = influencerData;
        console.log(this.influencer, '[Caraota]');

        // Asignar los videosInfluencer al arreglo de videos
        if (influencerData.videosInfluencer) {
          this.videos = influencerData.videosInfluencer.map((video) => ({
            video_id: video.video_id,
            path: video.path, // Asegúrate de que 'wistiaId' sea el campo correcto del backend
            title: video.title,
            description: video.description,
            video_url: video.video_url
          }));
        }
      }
    },
    methods: {
      editVideo(id) {
        console.log("Editing video with ID:", id)
        // Aquí puedes agregar la lógica para editar el video
      },
      async deleteVideoFromWistia(videoId) {
        try {
          const response = await axios.delete(`https://api.wistia.com/v1/medias/${videoId}.json`, {
            headers: {
              Authorization: `Bearer 74dcb98e1cc2fb852d30bb2c0d59cf13c0538ae6f141f704d5748945db275d4f`
            }
          });

          console.log('Video deleted from Wistia:', response.data);
        } catch (error) {
          console.error('Error deleting video from Wistia:', error);
          throw error; // Lanza el error para manejarlo en el método deleteVideo
        }
      },
      // deleteVideo(videoId, wistiaId) {
      //   const userId = this.$route.params.influencerId;
      //   const videoToDelete = this.videos.find(video => video.video_id === videoId);
        
      //   if (!videoToDelete) {
      //       console.error('Video not found');
      //       return;
      //   }

      //   if (confirm("Are you sure you want to delete this video?")) {
      //       this.deleteVideoFromWistia(wistiaId) // Aquí usas el wistiaId
      //           .then(() => {
      //               // Ahora puedes proceder a eliminar el video del backend
      //               return this.$http.delete('api/delete/videoInfluencer', {
      //                   data: {
      //                       videoId: videoId,
      //                       userId: userId
      //                   }
      //               });
      //           })
      //           .then(() => {
      //               // Actualizar la lista de videos
      //               this.videos = this.videos.filter(video => video.video_id !== videoId);
                    
      //               // Actualizar localStorage
      //               this.updateInfluencerLocalStorage(userId, videoId);
                    
      //               this.$notify({
      //                   group: 'notif',
      //                   type: 'success',
      //                   title: 'Success',
      //                   text: 'Deleted video successfully!',
      //               });
      //           })
      //           .catch(error => {
      //               console.error('Error deleting video:', error);
      //               this.$notify({
      //                   group: 'notif',
      //                   type: 'error',
      //                   title: 'Error',
      //                   text: 'Failed to delete video. Please try again.',
      //               });
      //           });
      //   }
      // },

    deleteVideo(videoId) {
        
        const userId = this.$route.params.influencerId;
        const videoToDelete = this.videos.find(video => video.video_id === videoId);

        if (!videoToDelete) {
            console.error('Video not found');
            return;
        }

        if (confirm("Are you sure you want to delete this video?")) {
            // Proceder a eliminar el video del backend y S3
            this.$http.delete('api/user/deleteVideoInfluencer', {
                data: {
                    videoId  : videoToDelete.video_id,
                    userId   : userId,
                    path : videoToDelete.path
                }
            })
            .then(() => {
                // Actualizar la lista de videos
                this.videos = this.videos.filter(video => video.video_id !== videoId);
                
                // Actualizar localStorage
                this.updateInfluencerLocalStorage(userId, videoId);
                
                this.$notify({
                    group: 'notif',
                    type: 'success',
                    title: 'Success',
                    text: 'Deleted video successfully!',
                });
            })
            .catch(error => {
                console.error('Error deleting video:', error);
                this.$notify({
                    group: 'notif',
                    type: 'error',
                    title: 'Error',
                    text: 'Failed to delete video. Please try again.',
                });
            });
        }
    },
    updateInfluencerLocalStorage(userId, videoId) {
  
          // Obtener los datos del influencer desde localStorage
          let influencerData = JSON.parse(localStorage.getItem(`influencer_${userId}`));


          // Asegurarse de que haya datos y que tenga la propiedad videosInfluencer
          if (influencerData && Array.isArray(influencerData.videosInfluencer)) {
              // Filtrar los videos para eliminar el video con el ID proporcionado
              influencerData.videosInfluencer = influencerData.videosInfluencer.filter(video => {
                  // Comparar correctamente ambos valores como strings
                  return String(video.video_id) !== String(videoId);
              });

              // Actualizar el localStorage con los datos modificados
              localStorage.setItem(`influencer_${userId}`, JSON.stringify(influencerData));
          } else {
              console.warn('Influencer data or videosInfluencer not found in localStorage');
          }
      },
      // fetchInfluencerData(userId) {
      //   // Aquí iría tu lógica para obtener los datos del influencer desde el backend
      //   // Por ejemplo, usando axios
      //   axios.get(`/api/influencers/${userId}`)
      //     .then(response => {
      //       this.influencer = response.data; // Asigna los datos recibidos al objeto influencer
      //       localStorage.setItem(`influencer_${userId}`, JSON.stringify(this.influencer)); // Guarda los datos en localStorage
      //     })
      //     .catch(error => {
      //       console.error('Error al obtener los datos del influencer:', error);
      //     });
      // },
      openUploadModal() {
        this.showUploadModal = true;
      },
      closeUploadModal() {
        this.showUploadModal = false;
        this.file = null;
        this.videoPreview = null;
        this.videoTitle = '';  // Limpiar el título del video
        this.videoDescription = '';  // Limpiar la descripción del video
      },
      handleFileUpload(event) {
        this.file = event.target.files[0];
        if (this.file) {
          this.videoPreview = URL.createObjectURL(this.file);  // Crear URL temporal para previsualizar
        }
      },
      async uploadVideo() {
        if (!this.file || !this.videoTitle || !this.videoDescription) {
          alert('Please fill in all fields and select a video.');
          return;
        }

        this.isLoading = true;

        try {
          // Crear un FormData para enviar el archivo
          const formData = new FormData();
          formData.append('file', this.file); // 'file' debe coincidir con el nombre en multer
          formData.append('userId', this.influencer.userId); // Asegúrate de enviar el userId también

          // Paso 1: Subir el video y obtener la URL pre-firmada
          const response = await this.$http.post('api/user/setInfluencerVideo', formData, {
            headers: {
              'Content-Type': 'multipart/form-data', // Esto es importante
            },
          });

          await this.saveVideoToBackend(response.data.userContent, this.influencer.userId, response.data.videoUrl, response.data.videoHash);

          // console.log(response.data.userContent, '[]');

        } catch (error) {
          console.error('Error al subir el video:', error);
        } finally {
          this.isLoading = false;
        }
      },
      async saveVideoToBackend(userContent, userId, videoUrl, videoHash) {
        try {
          // Enviar la URL del video, el título y la descripción al backend
          const response = await this.$http.post('api/user/videoInfluencerTb', {
            userContent,
            userId: userId,
            videoUrl: videoUrl,
            title: this.videoTitle,
            description: this.videoDescription,
            path: userContent.path
          });

          console.log('Respuesta:', response);

          // Crear un nuevo objeto de video a partir de la respuesta del backend
          const newVideo = {
            video_id: response.data.video.idContent, // ID del video en la base de datos
            title: this.videoTitle,
            description: this.videoDescription,
            video_url: response.data.video.videoUrl,
            path: response.data.video.path,// URL del video en S3
          };

          // Agregar el nuevo video al arreglo de videos
          this.videos.push(newVideo);

          // Revisar si el localStorage ya tiene datos del influencer
          let influencerData = JSON.parse(localStorage.getItem(`influencer_${userId}`));

          // Si no existe, crear la estructura básica
          if (!influencerData) {
            influencerData = {
              videosInfluencer: [] // Estructura vacía si no había videos antes
            };
          }

          // Agregar el nuevo video al arreglo de videos en localStorage
          influencerData.videosInfluencer.push(newVideo);

          localStorage.setItem(`influencer_${userId}`, JSON.stringify(influencerData));

          // Notificar al usuario y cerrar el modal
          this.$notify({
            group: 'notif',
            type: 'success',
            title: 'Success!',
            text: 'Added Video successfully!'
          });

          this.closeUploadModal(); // Cierra el modal
        } catch (error) {
          console.error('Error al guardar el video en el backend:', error);
        }
      },
  
      // async uploadVideo() {
      //   if (!this.file || !this.videoTitle || !this.videoDescription) {
      //     alert('Please fill in all fields and select a video.');
      //     return;
      //   }

      //   this.isLoading = true;

      //   const formData = new FormData();
      //   formData.append('file', this.file);
      //   formData.append('project_id', '9365980');  // ID del proyecto de Wistia


      //   try {
      //     // Subir el video a Wistia
      //     const response = await axios.post('https://upload.wistia.com', formData, {
      //       headers: {
      //         Authorization: `Bearer 74dcb98e1cc2fb852d30bb2c0d59cf13c0538ae6f141f704d5748945db275d4f`,
      //         'Content-Type': 'multipart/form-data',
      //       },
      //     });

      //     const videoId = response.data.hashed_id;

      //     // Guardar el video en el backend con la URL generada y otros datos
      //     await this.saveVideoToBackend(this.influencer.userId, videoId);
          
      //     // Recargar la tabla y cerrar el modal
      //     this.$refs.vuetable.reload();
      //     this.closeUploadModal();
      //   } catch (error) {
      //     console.error('Error al subir el video:', error);
      //   } finally {
      //     this.isLoading = false;
      //   }
      // },
      // async saveVideoToBackend(userId, videoId) {
      //   console.log(videoId, 'USERID');
      //   const videoUrl = `https://fast.wistia.net/embed/iframe/${videoId}`;

      //   try {
      //     // Enviar la URL del video, el título y la descripción al backend
      //     const response = await this.$http.post('api/upload/videoInfluencer', {
      //       userId: userId,
      //       videoUrl: videoUrl,
      //       title: this.videoTitle,
      //       description: this.videoDescription,
      //       wistiaId: videoId,
      //     });

      //     console.log('[Respuesta mijo]', response);

      //     // Aquí se crea un nuevo objeto de video a partir de la respuesta del backend
      //     const newVideo = {
      //       video_id: response.data.video.id, // El ID del video desde la respuesta del backend
      //       wistia_id: videoId,                // El Wistia ID
      //       title: this.videoTitle,
      //       description: this.videoDescription,
      //       url: videoUrl,                     // La URL del video
      //     };

      //     // Agregar el nuevo video al arreglo de videos
      //     this.videos.push(newVideo); // Esto actualiza la lista de videos en tiempo real

      //     // Revisar si el localStorage ya tiene datos del influencer
      //     let influencerData = JSON.parse(localStorage.getItem(`influencer_${userId}`));

      //     // Si no existe, crear la estructura básica
      //     if (!influencerData) {
      //       influencerData = {
      //         videosInfluencer: [], // Estructura vacía si no había videos antes
      //       };
      //     }

      //     // Agregar el nuevo video al arreglo de videos en localStorage
      //     influencerData.videosInfluencer.push(newVideo);
          
      //     localStorage.setItem(`influencer_${userId}`, JSON.stringify(influencerData));

      //     // Notificar al usuario y cerrar el modal
      //     this.$notify({
      //       group: 'notif',
      //       type: 'success',
      //       title: 'Success!',
      //       text: 'Added Video successfully!',
      //     });

      //     this.closeUploadModal(); // Cierra el modal
      //   } catch (error) {
      //     console.error('Error al guardar el video en el backend:', error);
      //   }
      // }
    }
  };
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/influencers/influencer-list";
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1040;
  }

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  width: 90%;
  max-width: 500px;
  background-color: white;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  height: auto;
}

.btn.btn-link {
  background: #22bb33;
  color: white;
}
.buttons-influencers a, .buttons-influencers button{
  margin: 0 5px;
}

.btn-close {
    background: none;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .btn-close i {
    color: #000; /* Cambia el color del icono si es necesario */
  }

  .btn-close:hover i {
    color: #ff0000; /* Cambia el color al pasar el cursor (opcional) */
  }

  .modal-backdrop {
  opacity: 0.9 !important;
}

.table td, .table th {
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  text-overflow: ellipsis;
}
</style>